import React from 'react'

function Header() {
  return (
    <div className='Header'>
    <nav className="navbar navbar-expand-lg bg-body-tertiary">
    <div className="container">
      <a className="navbar-brand" href="/">
        <img src="Assets/MP-logo.png" className='logo' alt="" />
      </a>
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon" />
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
          <li className="nav-item">
            <a className="nav-link" aria-current="page" href="#home">
              Home
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#service">
              Our Services
            </a>
          </li>
        
          <li className="nav-item">
            <a className="nav-link" href='#work'>
              Work Gallery
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href='#area'>
            Services Area
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href='#contact'>
            Contact Us
            </a>
          </li>
        </ul>
        
      </div>
    </div>
  </nav>
  </div>
  )
}

export default Header