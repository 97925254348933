import React from 'react'

function Service() {
  return (
    <div className='Services' id='service'>
    <div className='container'>
    <div className='service-heading'>
    <h1>Our Services</h1>
    <p className='service-para'>Quality Home Maintenance Services - Keeping Your Home in Top <br /> Shape</p>
    </div>
    <div className="row">
    <div className="col-md-4"><div className="card" style={{ width: "18rem" }}>
    <img src="Assets/home.png" className="card-img-top" alt="..." />
    <div className="card-body">
      <p className="card-text">
        Home Repair
      </p>
    </div>
  </div></div>
    <div className="col-md-4"><div className="card" style={{ width: "18rem" }}>
    <img src="Assets/appliance.png" className="card-img-top" alt="..." />
    <div className="card-body">
      <p className="card-text">
        Appliances Repair
      </p>
    </div>
  </div></div>
    <div className="col-md-4"><div className="card" style={{ width: "18rem" }}>
    <img src="Assets/remodeling.png" className="card-img-top" alt="..." />
    <div className="card-body">
      <p className="card-text">
       Remodeling
      </p>
    </div>
  </div></div>
    </div>
    <div className="row">
    <div className="col-md-4"><div className="card" style={{ width: "18rem" }}>
    <img src="Assets/painting.png" className="card-img-top" alt="..." />
    <div className="card-body">
      <p className="card-text">
     Painting
      </p>
    </div>
  </div></div>
    <div className="col-md-4"><div className="card" style={{ width: "18rem" }}>
    <img src="Assets/electric.png" className="card-img-top" alt="..." />
    <div className="card-body">
      <p className="card-text">
        Electrical Maintenance
      </p>
    </div>
  </div></div>
    <div className="col-md-4"><div className="card" style={{ width: "18rem" }}>
    <img src="Assets/plumbing.png" className="card-img-top" alt="..." />
    <div className="card-body">
      <p className="card-text">
        Plumbing
      </p>
    </div>
  </div></div>
    </div>
    <div className="row">
    <div className="col-md-4"></div>
    <div className="col-md-4"><div className="card" style={{ width: "18rem" }}>
    <img src="Assets/washing.png" className="card-img-top" alt="..." />
    <div className="card-body">
      <p className="card-text">
        Power Washing
      </p>
    </div>
  </div></div>
    <div className="col-md-4"></div>
    </div>
    </div>
    </div>
  )
}

export default Service